/* Global helpers, written with prefix .h ------------
 ----------------------------------------------------- */

/* Helpers for aligment ----------------------------------
   ----------------------------------------------------- */
.pull-left {
  float: left !important;
}

.ml-0 {
  margin-left: 0px;
}

.mt-0 {
margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}
.red-inner-txt {
  color: #b41041;
}

.salad-txt-inner {
  color: #b9ce0d;
}


.green-txt-inner {
  color: #017e4e;
}

.pull-right {
  float: right !important;
}
/* Helpers for text ----------------------------------
  ----------------------------------------------------- */

.h-text-light {
  font-weight: $fw-light;
}
.h-text-bold {
  font-weight: $fw-bold;
}
.h-text-black {
  font-weight: $fw-black;
}

.h-text-center {
  text-align: center;
}
.h-text-left {
  text-align: left;
}
.h-text-right {
  text-align: right;
}

.h-text-lgup {
  font-size: 4rem;
}

.h-text-lg {
  font-size: 2rem;
}

.h-text-mdup {
  font-size: 1.6rem;
}

.h-text-md {
  font-size: 1.2rem;
}

.h-text-sm {
  font-size: 0.9rem;
}

.h-text-xxs {
  font-size: 0.66rem;
}

.h-text-upper {
  text-transform: uppercase;
}

.h-text-capitalized {
  text-transform: capitalize;
}

.h-text-ellipsis {
  white-space: nowrap;
  display: block;
  overflow: hidden;
  text-overflow: ellipsis;
}

.h-text-primary-cl {
  color: $primary-cl!important;
}
.h-hover-text-primary-cl:hover {
  color: $primary-cl;
}
.h-hover-text-primary-dark-cl:hover {
  color: $primary-dark-cl;
}
.h-text-grey-cl {
  color: $grey-cl;
}
.h-text-success {
  color: $success-cl;
}
.h-text-error {
  color: $error-cl;
}
.h-hover-text-error:hover {
  color: $error-cl;
}
.h-text-warn {
  color: $accent-light-cl;
}

.h-text-white {
  color: $white-cl;
}

.h-main-shadow {
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.5);
}

/* Helpers for behaviour -----------------------------
 ----------------------------------------------------- */

.h-pointer {
  cursor: pointer;
}

/* Flexbox utilities ---------------------------------
 ----------------------------------------------------- */

.h-flexbox {
  display: flex;
  &.flexbox-inline {
    display: inline-flex;
  }
  &.vertical {
    flex-direction: column;
  }
  &.items-center {
    align-items: center;
  }
  &.items-stretch {
    align-items: stretch;
  }
  &.items-end {
    align-items: flex-end;
  }
  &.justify-start {
    justify-content: flex-start;
  }
  &.justify-center {
    justify-content: center;
  }
  &.justify-end {
    justify-content: flex-end;
  }
  &.justify-between {
    justify-content: space-between;
  }
  &.justify-around {
    justify-content: space-around;
  }
  &.flex-wrap {
    flex-wrap: wrap;
  }
}

.h-grow {
  flex-grow: 1;
}

.h-flex-shrink-0 {
  flex-shrink: 0;
}

.h-flex-basis-0 {
  flex-basis: 0;
}

.h-no-grow {
  flex-grow: 0;
}

.h-self-center {
  align-self: center;
}

.h-self-start {
  align-self: flex-start;
}
.h-self-end {
  align-self: flex-end;
}

/* Display utilities ---------------------------------
 ----------------------------------------------------- */
.h-inline-block {
  display: inline-block;
}

.h-d-none {
  display: none;
}

.h-pos-relative {
  position: relative;
}

.h-pos-absolute {
  position: absolute;
}

.h-novisibility {
  visibility: hidden;
}

.h-opacity-0 {
  opacity: 0;
}

/* Sizing utilities ---------------------------------
 ----------------------------------------------------- */

.h-overflow-a {
  overflow: auto;
}

.h-overflow-v {
  overflow: visible!important;
}

.h-overflow-h {
  overflow: hidden;
}

.h-height-100 {
  height: 100% !important;
}

.h-height-0 {
  height: 0!important;
}

.h-height-100vh {
  height: 100vh;
}

.h-width-100 {
  width: 100% !important;
}
.h-width-auto {
  width: auto !important;
}

.h-width-50 {
  width: 50%;
}
.h-min-width-30 {
  min-width: 30%;
}

.h-width-25 {
  width: 25%;
}

.h-mx-height-100 {
  max-height: 100%;
}

.h-mn-height-100 {
  min-height: 100%;
}

.h-no-wrap {
  white-space: nowrap;
}

.h-break-all {
  word-break: break-all;
}

/* Styling utilities ---------------------------------
 ----------------------------------------------------- */
.no-border {
  border: none;
}
@for $i from 0 through 4 {
  .m-#{$i} {
    margin: 0rem + $i !important;
  }
  .mx-#{$i} {
    margin-left: 0rem + $i !important;
    margin-right: 0rem + $i !important;
  }
  .my-#{$i} {
    margin-top: 0rem + $i !important;
    margin-bottom: 0rem + $i !important;
  }
  .mt-#{$i} {
    margin-top: 0rem + $i !important;
  }
  .mr-#{$i} {
    margin-right: 0rem + $i !important;
    @media (max-width: 768px) {
    margin-right: auto !important;
  }
  }
  .mb-#{$i} {
    margin-bottom: 0rem + $i !important;
  }
  .ml-#{$i} {
    margin-left: 0rem + $i !important;
    @media (max-width: 768px) {
    margin-left: auto !important;
  }
  }
  .p-#{$i} {
    padding: 0rem + $i !important;
  }
  .px-#{$i} {
    padding-left: 0rem + $i !important;
    padding-right: 0rem + $i !important;
    @media (max-width: 1099px) {
      margin-left: auto !important;
    }
  }
  .py-#{$i} {
    padding-top: 0rem + $i !important;
    padding-bottom: 0rem + $i !important;
  }
  .pt-#{$i} {
    padding-top: 0rem + $i !important;
  }
  .pt-#{$i} {
    padding-top: 0rem + $i !important;
  }
  .pr-#{$i} {
    padding-right: 0rem + $i !important;
  }
  .pb-#{$i} {
    padding-bottom: 0rem + $i !important;
  }
  .pl-#{$i} {
    padding-left: 0rem + $i !important;
  }
}

@for $y from 0 through 3 {
  @for $i from 1 through 99 {
    .m-#{$y}-#{$i} {
      margin: (0rem + $y) + ($i * 0.01) !important;
    }
    .mx-#{$y}-#{$i} {
      margin-left: (0rem + $y) + ($i * 0.01) !important;
      margin-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .my-#{$y}-#{$i} {
      margin-top: (0rem + $y) + ($i * 0.01) !important;
      margin-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .mt-#{$y}-#{$i} {
      margin-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .mt-#{$y}-#{$i} {
      margin-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .mr-#{$y}-#{$i} {
      margin-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .mb-#{$y}-#{$i} {
      margin-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .ml-#{$y}-#{$i} {
      margin-left: (0rem + $y) + ($i * 0.01) !important;
    }
    .p-#{$y}-#{$i} {
      padding: (0rem + $y) + ($i * 0.01) !important;
    }
    .px-#{$y}-#{$i} {
      padding-left: (0rem + $y) + ($i * 0.01) !important;
      padding-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .py-#{$y}-#{$i} {
      padding-top: (0rem + $y) + ($i * 0.01) !important;
      padding-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .pt-#{$y}-#{$i} {
      padding-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .pt-#{$y}-#{$i} {
      padding-top: (0rem + $y) + ($i * 0.01) !important;
    }
    .pr-#{$y}-#{$i} {
      padding-right: (0rem + $y) + ($i * 0.01) !important;
    }
    .pb-#{$y}-#{$i} {
      padding-bottom: (0rem + $y) + ($i * 0.01) !important;
    }
    .pl-#{$y}-#{$i} {
      padding-left: (0rem + $y) + ($i * 0.01) !important;
    }
  }
}
