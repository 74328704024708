@font-face {
    font-family: "Avanti";  
    src: url("../../fonts/avanti.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: normal;
 }

 @font-face {
    font-family: "Bahnschrift";  
    src: url("../../fonts/bahnschrift.ttf") format("truetype"); 
    font-style: normal; 
    font-weight: normal;
 }

 @font-face {
    font-family: "Gals";  
    src: url("../../fonts/gals.otf") format("opentypee"); 
    font-style: normal; 
    font-weight: normal;
 }
