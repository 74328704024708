.main-section {
    background: url(https://i.imgur.com/klnOmIy.jpg);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center 80%;
    // min-height: 100vh;
    height: 100vh;
    @media (min-width: 768px) and (max-width: 1500px) {
        transform: scale(0.8);
    }
    background-color: #000;
    padding-top: 0px;
    @media (max-width:1300px) {
      min-height: auto;
      height: auto;
      padding: 50px 30px;
    }
    @media (max-width: 825px) {
      padding: 10px 20px;
    }
    // @media (max-width:1500px) {
    //   height: 100vh;
    // }
    // @media (min-width:1501px) {
    //   height: 900px;
    // }
    .main-container{
      max-width: 1150px;
      margin: 0 auto;
      @media (max-width:1300px) {
        max-width: 100%;
        width: 100%;
        margin: 0 auto;
      }
      .inner-wrap {
        // padding-left: 110px;
      .main-nav {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 140px; 
        @media (max-width: 1300px) {
          margin-bottom: 120px;
        }
        @media (max-width:1023px) {
          margin-bottom: 20px;
        }
        @media (max-width: 450px) {
          flex-direction: column;
          align-items: center;
          a {
            img {
                 max-width: 170px;
            }
          }
        }
        
      }
      .main-hero {
        position: relative;
        text-align: center;
        max-width: 860px;
        margin: 0 auto;
        @media (max-width:1300px) {
          font-size: 0;
        }
        .arrow-color {
          @media (max-width: 1300px) {
            display: none;
          }
          &__first {
            position: absolute;
            top: 0px;
            left: 325px;
          }
          &__second {
            position: absolute;
            top: 90px;
            left: 250px;
          }
          &__third {
            position: absolute;
            bottom: 122px;
            left: 327px;
          }
          &__fourth {
            position: absolute;
            top: 0;
            right: 325px;
          }
          &__fifth {
            position: absolute;
            top: 90px;
            right: 250px;
          }
          &__sixth {
            position: absolute;
            bottom: 122px;
            right: 327px;
          }
        }
        img {
          @media (max-width:1300px) {
            height: 150px;
            display: block;
            margin: 0 auto;
            margin-bottom: 20px;
          }
          @media (max-width: 895px) {
            height: 65px;
          }
          @media (max-width: 767px) {
                height: 45px;
          }
        }
        .label {
          background-size: cover;
          width: 290px;
          height: 120px;
          position: absolute;
          background-repeat: no-repeat;
          background-size: cover;
          color: #f7f3f5;
          font-size: 30px;
          display: flex;
          align-items: center;
          justify-content: center;
          text-align: center;
          font-weight: normal;
          @media (max-width:1023px) {
            position: static;
            margin: 0 auto;
            display: inline-flex;
            align-items: center;
            justify-content: center;
            padding-top: 20px;
            max-width: 100%;
            width: 100%;
            height: 100px;
            margin-bottom: 20px;
            &:last-child {
              margin-bottom: 0;
            }
          }
          @media (max-width: 825px) {
            height: 45px;
            font-size: 17px;
            br {
              display: none;
            }
          }
          @media (max-width: 767px) {
            height: 30px;
          }
          &.label1 {
            top: -85px;
            background: url(../img/list1.png) no-repeat;
            background-size: cover;
          }
          &.label2 {
            top: 90px;
            left: -50px;
            background: url(../img/green-box.png) no-repeat;
            background-size: cover;
          }
          &.label3 {
            top: 290px;
            background: url(../img/yellow-box.png) no-repeat;
            background-size: cover;
          }
          &.label4 {
            top: -85px;
            right: 0;
            background: url(../img/yellow-box.png) no-repeat;
            background-size: cover;
          }
          &.label5 {
            top: 90px;
            right: -80px;
            background: url(../img/list1.png) no-repeat;
            background-size: cover;
          }
          &.label6 {
            top: 290px;
            right: 0px;
            background: url(../img/green-box.png)no-repeat;
            background-size: cover;
          }
        }
        .list-logo {
            padding-top: 80px;
            @media (max-width: 1300px) {
              padding-top: 40px;
              img {
                width: auto;
                height: auto;
              }
            }
            @media (max-width: 1023px) {
              padding-top: 0px;
              max-width: 140px;
              margin: 0 auto;
            }
            @media (max-width: 767px) {
              img {
                display: none;
              }
            }
        }
      }
    }
  }
}

.black-section {
  height: 600px;
  margin-top: -1px;
  background-color: #000;
  margin-left: 170px;
  .info-wrap {
    max-width: 1090px;
    position: relative;
    .distrib {
    position: absolute;
    right: 0;
    }
    .blackhero {
    max-width: 630px;
    margin-top: 40px;
    margin-right: 70px;
    margin-left: 50px;
    }
    .arrowhero {
      position: absolute;
    }
    .gtulogo {
      margin-left: 40px;
    }
  }
}
.main-info {
  &.mw-444 {
    max-width: 445px !important;
  }
  &.mw-454 {
    max-width: 454px !important; 
    text-align: right;
    @media (min-width: 1600px) {
        max-width: 894px !important;
    }
  }
  &.mw-410 {
    max-width: 500px !important;
    text-align: right;
  }
}

.sectionsz {
  background: url(../img/Block1.png);
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  // min-height: 1060px;
  // padding-bottom: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    height: auto;
    background-size: 190%;
  }
  .detail-btn {
    font-family: $font-gals;
    &:focus {
      color: #fff;
    }
    &:active {
      color: #fff;
    }
  }
  .right-part-wrap {
    text-align: right;
    @media (max-width: 768px) {
      margin-top: 40px;
      text-align: center;
      img {
            max-width: 150px !important;
      }
    }
    img {
      width: 100%;
      max-width: 260px;
      @media (min-width: 1600px) {
          max-width: 350px;
      }
      @media (min-width: 1850px) {
          max-width: 450px;
          width: 100%;
      }
    }
  }
  .block-title {
    font-size: 30px;
    color: #fff;
    @media (min-width: 1600px) {
      font-size: 52px;
    }
    @media (max-width: 767px) {
      font-size: 19px;
    }
  }
  .main-info {
    font-size: 21px;
    max-width: 270px;
    color: #fff;
    @media (min-width: 1600px) {
        font-size: 30px;
    }
    @media (min-width: 1850px) {
          font-size: 38px;
    }
    font-family: $font-bahnschrift;
    @media (max-width: 768px) {
          text-align: center;
              font-size: 15px;
    }
    @media (max-width: 1200px) {
      max-width: 660px !important;
    }
  }
  .fp-tableCell {
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
}

.sectionsz2 {
  background: url(../img/BlockNew.png);
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  // min-height: 1060px;
  // padding-bottom: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  @media (min-width: 1600px) {
    background-position: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    // min-height: 910px;
    height: auto;
    background-size: 150%;
    background-position: 100% 0%;
  }
  .detail-btn {
    &.green {
      font-family: $font-gals;
    }
  }
  .left-part-wrap {
    text-align: left;
    @media (max-width: 768px) {
      text-align: center;
      img {
            max-width: 150px;
      }
    }
    @media (min-width: 1600px) {
      img {
          max-width: 350px;
          width: 100%;
      }
    }
    @media (min-width: 1850px) {
      img {
          max-width: 450px;
          width: 100%;
      }
    }
  }
  .block-title {
    font-size: 30px;
    color: #fff;
    text-align: center;
    @media (min-width: 1600px) {
      font-size: 52px;
    }
    @media (max-width: 768px) { 
      margin-top: 30px;
      font-size: 20px;
    }
  }
  .main-info {
    font-size: 21px;
    max-width: 270px;
    color: #fff;
    font-family: $font-bahnschrift;
    @media (min-width: 1600px) {
        font-size: 30px;
        max-width: 710px;
    }
    @media (min-width: 1850px) {
          font-size: 38px;
    }
    @media (max-width: 768px) {
          text-align: center;
              font-size: 16px;
    }
    &.mw-570 {
      max-width: 434px;
      @media (min-width: 1600px) {
        max-width: 710px;
    }
      @media (max-width: 1200px) { 
        max-width: 720px !important;
      }
    }
  }
  .fp-tableCell {
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
}

.sectionsz3 {
  background: url(../img/Block3.png);
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  // min-height: 1060px;
  padding-bottom: 100px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  @media(max-width: 768px) {
    display: flex;
    flex-direction: column;
    align-items: center;
    // min-height: 930px;
    height: auto;
    padding: 0px 30px;
    background-size: 180%;
  }
  .detail-btn {
    border: 4px solid #adc00c;
    &:focus {
      color: #fff;
    }
    &:active {
      color: #fff;
    }
    &:hover {
      background-color: #adc00c;
    }
  }
  .right-part-wrap {
    text-align: right;
    @media(max-width: 768px) {
      text-align: center;
      margin-top: 30px;
      img {
            max-width: 150px;
      }
    }
    .detail-btn {
      @media(max-width: 768px) {
        // margin: 0 auto;
      }
    }
  }
  .block-title {
    font-size: 30px;
    color: #fff;
    @media (min-width: 1600px) {
      font-size: 52px;
    }
    @media (max-width: 768px) {
        font-size: 21px;
    }
  }
  .main-info {
    font-size: 21px;
    color: #fff;
    font-family: $font-bahnschrift;
    @media (min-width: 1600px) {
        font-size: 30px;
    }
    @media (min-width: 1850px) {
          font-size: 38px;
    }
    @media(max-width: 1200px) {
      max-width: 690px !important;
    }
    @media (max-width: 767px) {
      font-size: 15px;
    }
  }
  .fp-tableCell {
    @media (max-width: 768px) {
        justify-content: center;
    }
  }
}

.sectionsz4 {
  background: url(../img/Block4.png);
  background-repeat: no-repeat;
  background-color: #000;
  // background-size: cover;
  // padding-top: 170px;
  background-size: 100%;
  // min-height: 1060px;
  font-family: $font-bahnschrift;
  @media (min-width: 1600px) {
    background-size: cover;
    background-position: center;
  }
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    padding: 0px 30px;
    // min-height: 1060px;
    height: auto;
    background-size: 100%;
  }
  .sectionsz4-container {
    max-width: 990px;
    width: 100%;
    margin: 0 auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    @media (min-width: 1600px) {
      justify-content: space-evenly;
      max-width: 1410px;
    }
    @media (max-width: 1200px) {
      padding: 0 30px;
    }
    @media (max-width: 767px) {
      justify-content: center;
    }
  }
  .sectionsz4-top {
    margin: 140px 0 0 0;
    @media (max-width: 768px) {
      display: flex;
      flex-direction: column;
      align-items: center;
      margin: 20px 0px 0px 0px;
    }
    @media (min-height:1850px) {
          margin-bottom: 290px;
    }
    .company-item {
      display: flex;
      align-items: center;
      justify-content: space-between;
      max-width: 265px;
      margin-bottom: 45px;
      
      @media (min-width: 1600px) {
        max-width: 495px;
      }
      @media (min-height: 1850px) {
        font-size: 55px;
        max-width: 665px;
      }
      @media (max-width: 767px) {
        margin-bottom: 15px;
        img {
          max-width: 40px;
        }
      }
      &:last-child {
        margin-bottom: 0;
      }
      .company-txt {
        margin-right: 30px;
        font-size: 24px;
        color: #ffffff;
        @media (min-width: 1600px) {
        font-size: 45px;
      }
      @media (min-height: 1850px) {
        font-size: 55px;
        max-width: 665px;
      }
      @media (max-width: 767px) {
        font-size: 16px;
      }
      }
      .company-logo {

      }
      .company-img {
        @media (min-height: 1850px) {
              max-width: 100px;
            width: 100%;
        }
      }
    }
  }
  .sectionsz4-bottom {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    @media (max-width: 768px) {
      flex-direction: column;
      margin-top: 40px;
    }
    @media (min-width: 1600px) {
        padding-top: 200px;
    }
    @media (min-height: 1850px) {
      padding-top: 500px;
    }
  }
  .block-title {
    font-size: 30px;
    color: #fff;
    text-align: center;
    @media (min-width: 1600px) {
      font-size: 52px;
    }
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
      margin-top: 20px;
      font-size: 15px;
    }
  }
  .main-info {
    font-size: 21px;
    max-width: 460px;
    color: #fff;
    @media (min-width: 1600px) {
        font-size: 35px;
            max-width: 650px;
    }
    @media (max-width: 1200px) {
      max-width: 630px;
      width: 100%;
    }
    @media (max-width: 768px) {
      width: 100%;
      text-align: center;
      max-width: 100%;
      font-size: 16px;
    }
  }
}

.sectionsz5 {
  background: url(../img/patt.png);
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  min-height: 860px;
  padding-bottom: 70px;
  // height: 100%;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 768px) {
    min-height: auto;
    height: auto;
    padding: 0px 30px;
  }
  .section-title {
    font-size: 48px;
    color: #fff;
    margin-top: 60px;
    margin-bottom: 60px;
    @media (max-width: 768px) {
      font-size: 24px;    
    }
    @media (min-width: 1790px) {
          font-size: 78px;
    }
  }
  img {
    @media (max-width: 768px) {
      width: 300px;
      height: 100px;  
    }
  }
  .contact-wrap {
    display: flex;
    align-items: center;
    justify-content: center;
    img {
      margin-right: 20px;
      @media (max-width: 768px) {
        width: auto;
        height: auto;  
      }
    }
  }
}

.sectionsz6 {
  background: url(../img/Block6.png);
  background-repeat: no-repeat;
  background-color: #000;
  background-size: cover;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  .fp-tableCell {
    @media (max-width: 767px) {
          justify-content: center;
          .left-part-wrap {
            img {
              max-width: 100px;
            }
          }
    }
  }
  @media (min-width:1600px) {
      background-position: center;
  }
  @media (max-width: 768px) {
    // min-height: 1099px;
    height: auto;
    padding: 0px 30px;
    flex-direction: column;
    align-items: center;
    text-align: center;
      background-size: 150%;
    background-position: 100% 0%;
  }
  .detail-btn {
    &.green {
      font-family: $font-gals;
    }
  }
  .block-title {
    font-size: 30px;
    text-align: center;
    color: #fff;
    font-family: $font-regular;
    @media (min-width: 1600px) {
      font-size: 52px;
    }
    @media (max-width: 768px) {
      margin-top: 15px;
      font-size: 18px;
    }
  }
  .main-info {
    font-size: 21px;
    color: #fff;
    font-family: $font-bahnschrift;
    @media (min-width: 1600px) {
        font-size: 30px;
    }
    @media (min-width: 1850px) {
          font-size: 38px;
    }
    @media (max-width: 768px) {
          text-align: center;
          font-size: 16px;
    }
  }
}

.sectionsz7 {
  background: url(../img/BlockLast.png);
  background-repeat: no-repeat;
  background-color: #000;
  background-position: 0% top;
  // padding-bottom: 100px;
  // min-height: 1060px;
  display: flex;
  align-items: flex-end;
  justify-content: space-around;
  position: relative;
  @media (min-width: 1600px) {
      background-size: contain;
  }
  @media (max-width: 1050px) {
    flex-direction: column;
    align-items: center;
    padding: 50px 30px;
    min-height: auto;
    height: auto;
  }
  @media (max-width:768px) {
    background-size: 130%;
    padding: 0px 30px;
  }
  .randevoo-logo {
    position: absolute;
    bottom: 215px;
    right: 10%;
     @media (min-width: 1600px) {
        position: initial;
        margin-top: 20px;
        max-width: 205px;
        height: 190px;
        margin-bottom: 30px;
        width: 100%;
    } 
    @media (max-width: 1050px) {
      position: static;
      margin-top: 30px;
    }
    @media (max-width: 767px) {
      position: initial;
      max-width: 153px;
      height: 40%;
      width: 100%;
    }
  }
  .block-title {
    font-size: 30px;
    text-align: center;
    color: #fff;
    @media (min-width: 1600px) {
        font-size: 50px;
        text-shadow: 2px 2px #000;
        background-color: rgba(0,0,0,0.63);
        padding: 10px;
        br {
          display: none;
        }
    }
    // @media (min-height: 1800px) {
    //       position: absolute;
    //     margin-top: -610px;
    // }
    @media (max-width: 768px) {
      margin-top: 15px;
      text-shadow: 2px 2px #000;
      font-size: 21px;
    }
  }
  .main-info {
    font-size: 21px;
    max-width: 270px;
    color: #fff;
    font-family: $font-bahnschrift;
    text-align: left;
    @media (min-width: 1600px) {
       font-size: 32px;
    text-align: center;
     background-color: rgba(0, 0, 0, 0.63);
          padding: 10px;
          .red-inner-txt {
            color: #fff;
          }
    }
    @media (max-width: 768px) {
      max-width: 100px;
      width: 100%;
      font-size: 15px;
      text-align: center;
          background-color: rgba(0, 0, 0, 0.63);
          padding: 10px;
          // border: 1px solid #fff;
          .red-inner-txt {
            color: #fff;
          }
    }
  }
  .detail-btn {
    margin: 0;
    width: 270px;
    @media (min-width: 1600px) {
    font-size: 34px;
    height: 100px;
    max-width: 430px;
    width: 430px;
    }
    @media (max-width: 1050px) {
      margin-top: 30px;
    }
    @media (max-width: 768px) {
      margin: 0 auto;
      margin-top: 40px;
    }
  }
  .fp-tableCell {
    @media (min-width: 2400px) {
        max-width: 2250px;
        margin: 0 auto;
    }
    @media (min-width: 1600px) {
    // flex-direction: column;
    padding-left: 20px;
    padding-right: 20px;
    // align-items: center;
    // justify-content: center;
    }
    @media (max-width: 767px) {
      justify-content: space-evenly;
    }
  }
}

.detail-btn {
    display: flex;
    align-items: center;
    justify-content: center;
    max-width: 260px;
    padding-top: 5px;
    height: 60px;
    margin: 0 auto;
    margin-top: 50px;
    margin-bottom: 50px;
    border: 4px solid #b41041;
    border-radius: 10px;
    font-size: 24px;
    transition: .3s ease-in-out;
    color: #fff;
    @media (max-width: 767px) {
      height: 50px;
      margin: 0 auto;
      margin-top: 30px;
      margin-bottom: 20px;
      border-radius: 10px;
      font-size: 19px;
    }
    @media (min-width: 1600px) {
      max-width: 500px;
      height: 130px;
      font-size: 37px;
    }
    &:hover {
        background-color: #b41041;
        color: #fff;
      }
    &.green {
      border: 4px solid #01c278;
      &:hover {
        background-color: #01c278;
        color: #fff;
      }
    }
}



.main-nav-language-wrap {
          display: flex;
          @media (max-width: 500px) {
            margin-top: 20px;
          }
          .main-nav-language-item {
            width: 37px;
            height: 37px;
            display: flex;
            justify-content: center;
            border-radius: 8px;
            padding: 9px 0;
            margin-right: 7px;
            cursor: pointer;
            transition: .2s ease;
            color: #ffffff;
            &:last-child {
              margin-right: 0;
            }
            &.red {
              border: 2px solid #b41041;
              &:hover {
                background-color: #b41041; 
              }
              &.active {
                background-color: #b41041; 
              }
            }
            &.dark-green {
              border: 2px solid #017e4e;
              &:hover {
                background-color: #017e4e; 
              }
              &.active {
                background-color: #017e4e; 
              }
            }
            &.yellow {
              border: 2px solid #b9ce0d;
              &:hover {
                background-color: #b9ce0d; 
              }
              &.active {
                background-color: #b9ce0d; 
              }
            }
            &.light-green {
              border: 2px solid #01cf80;
              &:hover {
                background-color: #01cf80; 
              }
              &.active {
                background-color: #01cf80; 
              }
            }
          }
        }

.main-title-grid {
    color: #ff004c !important;
    font-size: 24px !important;
    line-height: 24px !important;
    margin-top: 0px !important;
    margin-bottom: 0px !important;
}

// .pd-to-140 {
//   padding-right: 140px;
//   @media (max-width: 1099px) {
//     padding-right: 0px;
//   }
// }

.uppcs {
  text-transform: uppercase;
}

.padding-remove {
  padding-left: 0px !important;
}

.for-mobile-nonepd {
  @media (max-width:1099px) {
    padding-left: 0px;
  }
}
.pdr {
  padding-right: 60px !important;
  @media (max-width: 1099px) {
    padding-right: 0px !important;
  }
}

.pdl {
  padding-left: 60px !important;
  @media (max-width: 1099px) {
    padding-left: 0px !important;
  }
}

.paddding0 {
  padding-left: 0px !important;
  padding-right: 0px !important;
}
.size-low {
  font-size: 22px;
  @media (max-width: 825px) {
    font-size: 17px;
  }
}

// .section {
//   height: auto !important;
//   min-height: 100vh !important;
//   padding-bottom: 50px;
//   @media (min-width: 1700px) {
//       background-size: 100% !important;
//       height: auto !important;
//       min-height: 950px !important;
//   }
// }

.up-btn {
  position: fixed;
  right: 25px;
  bottom: 25px;
  border-radius: 50%;
  width: 50px;
  height: 50px;
  opacity: .7;
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  cursor: pointer;
}

@media (max-width: 1023px) {
  .mobilez {
    display: flex;
  }
  .mobilez2 {
    display: flex;
  }
}

@media (max-width: 825px) {
  .mobilez {
    flex-direction: column;
  }
  .mobilez2 {
    margin-top: 20px;
    flex-direction: column;
  }
}


.main-section {
  .fp-tableCell {
    padding-bottom: 0px !important;
    height: auto !important;
    @media (min-height: 1500px) {
          transform: scale(1.4);
    }
  }
}