.gtu-page {
  .inner1-info-section .info-tree-section .info1 {
    padding-right: 60px;
    @media (max-width: 1099px) {
      padding-right: 0px;
    }
  }
  .inner1-info-section .info-tree-section .info2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .inner1-info-section .info-tree-section .info3 {
    padding-left: 60px;
    @media (max-width: 1099px) {
      padding-left: 0px;
    }
  }
  .inner-contact-section {
    // text-align: center;
    // .title {
    //   display: inline-block;
    //   position: relative;
    //   padding-right: 0px;
    //   img {
    //     position: absolute;
    //   }
    // }
  }
}


.inner1-main-section {
  background: url(../img/inner1main.png);
  background-repeat: no-repeat;
  background-size: cover;
  min-height: 460px;
  background-position: 50% 100%;
  position: relative;
  &.inner2-main {
    background: url(../img/inner2main.png);
    background-position: center 24.5%;
    min-height: 490px;
  }
  &.inner3-main {
    background: url(../img/innermain3.png);
    background-position: center 60%;
    min-height: 490px;
  }
  &.inner4-main {
    background: url(../img/inner44.png);
    background-position: 0% 100%;
    min-height: 490px;
  }
  &.inner5-main {
    background: url(../img/bginner5.png);
    background-position: center;
    background-position: 0% 16%;
    min-height: 490px;
  }
  .randevu-logo {
      position: absolute;
      right: calc(50% - 317.5px);
      bottom: -60px;
      @media (max-width: 650px) {
        bottom: -60px;
        width: 100%;
        right: initial;
      }
    }
    .main-container{
    max-width: 990px;
    padding-top: 70px;
    margin: 0 auto;
    position: relative;
    .main-nav {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 300px;
      padding-left: 30px;
      padding-right: 30px;
      @media (max-width: 500px) {
          flex-direction: column;
      }
    }
  }
}

.inner1-info-section {
  background-color: #000;
  padding-top: 100px;
  padding-bottom: 70px;
  .title {
    font-size: 30px;
    color: #ff004c;
    font-family: "Gals",arial;
    text-transform: uppercase;
    text-align: center;
    margin-bottom: 70px;
    img {
        padding-right: 15px;
      }
    &.little-green {
      color: #b9ce0d;
      text-transform: uppercase;
    }
    &.green {
      color: #01cf80;
      text-transform: uppercase;
      font-family: "Gals",arial;
    }
  }
  .info-center {
    font-size: 24px;
    color: #fff;
    max-width: 670px;
    margin: 0 auto;
    font-weight: 600;
    text-align: center;
    margin-bottom: 70px;
    font-family: $font-bahnschrift;
    &.mw-950 {
        max-width: 950px;
    }
  }
  .info-tree-section {
    .title {
      font-size: 30px;
      color: #ff004c;
      text-align: center;
      margin-bottom: 70px;
    }
    @media (max-width: 1099px) {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    max-width: 1200px;
    margin: 0 auto;
    margin-bottom: 70px;
    display: flex;
    justify-content: space-between;
    font-size: 18px;
    color: #fff;
    @media (max-width: 1250px) {
     padding-left: 30px;
    padding-right: 30px;
    }
    .info1 {
      text-align: left;
       max-width: 590px;
       font-family: $font-bahnschrift;
       @media (max-width: 1099px) {
        text-align: center;
      }
      &.mw-960 {
        max-width: 870px;
        width: auto;
        // padding-right: 50px;
      }
    }
    .info2 {
      text-align: center;
      max-width: 750px;
      padding-left: 70px;
      padding-right: 70px;
      font-family: $font-bahnschrift;
      @media (max-width: 1099px) {
        padding-left: 0px;
        padding-right: 0px;
        max-width: 670px;
        margin-top: 40px;
        margin-bottom: 40px;
      }
    }
    .info3 {
      text-align: right;
       max-width: 670px;
       font-family: $font-bahnschrift;
       @media (max-width: 1099px) {
        text-align: center;
      }
    }
  }
  .photo-grid {
        margin-bottom: 40px;
    .title {
      font-size: 30px;
      color: #ff004c;
      font-family: "Gals",arial;
      text-align: center;
      margin-bottom: 70px;
      text-transform: uppercase;
      img {
        padding-right: 15px;
      }
      &.little-green {
        color: #b9ce0d;
      }
      &.green {
        color: #01cf80;
      }
    }
    .grid-wrap {
      .grid1, .grid2, .grid3 {
        display: flex;
        align-items: center;
        justify-content: center;
        @media (max-width:1215px) {
          img {
              max-width: 320px;
          }
        }
        @media (max-width: 998px) {
          flex-direction: column;
        }
        &.inner2-page {
          img {
            max-width: 402px;
            width: 100%;
            @media (max-width: 1215px) {
              max-width: 320px;
            }
            @media (max-width: 998px) {
              max-width: 620px;
            }
          }
        }
      }
    }
    .pod-btn {
      display: flex;
      align-items: center;
      line-height: 23px;
      padding-top: 5px;
      text-align: center;
      text-transform: uppercase;
      justify-content: center;
      max-width: 215px;
      height: 70px;
      margin: 0 auto;
      margin-top: 70px;
      border: 4px solid #b41041;
      border-radius: 10px;
      font-size: 24px;
      transition: .3s ease-in-out;
      color: #fff;
      &:hover {
        background-color: #fff;
        border: 4px solid #fff;
        color: #b41041;
      }
    }
  }
  .inner-contact-section {
    .title {
      font-size: 30px;
      color: #ff004c;
      text-align: center;
      margin-bottom: 40px;
      // padding-right: 110px;
      // @media (max-width: 767px) {
      //   padding-right: 0px;
      // }
      &.pdr {
        padding-right: 0px;
      }
      img {
        padding-right: 15px;
      }
        &.green {
        color: #01cf80;
      }
      &.little-green {
        color: #b9ce0d;
      }
    }
    .contact-wrap {
      display: flex;
      align-items: center;
      justify-content: center;
      padding-top: 30px;
      img {
        margin-right: 20px;
      }
    }
  }
}


.hover-wrap {
  position: relative;
  cursor: pointer;
  @media (max-width:998px) {
      max-width: 320px;
  }
  &:hover {
    p {
      opacity: 1;
    }
  }
  p {
    transition: .2s ease-in-out;
    position: absolute;
    top: 0;
    background-color: rgba(0, 0, 0, 0.65);
    width: 100%;
    height: 100%;
    opacity: 0;
    line-height: 30px;
    color: #fff;
    font-size: 24px;
    text-align: center;
    padding-left: 30px;
    padding-right: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: $font-bahnschrift;
    &.white-color {
      color: #fff;
      span {
        text-align: left !important;
      }
    }
  }
}

body.inner1 {
  .inner-contact-section {
    .title {
      font-family: $font-gals;
    }
  }
}
body.inner5 {
  .inner1-info-section {
    .title {
      font-family: $font-gals;
      text-transform: uppercase;
    }
  }
  .photo-grid {
    .title {
      font-family: $font-gals;
      text-transform: uppercase;
    }
  }
  .info-center {
   font-family: $font-bahnschrift;
   font-size: 24px;
  }
  .photo-grid {
    .grid-wrap {
      .title {
        // font-family: $font-regular;
         font-family: 'Arial';
        &.flex-column-grid {
          flex-direction: column;
          font-size: 30px;
          line-height: 30px;
          .pt-18px {
            padding-top: 15px;
          }
          span {
            color: #fff;
            font-size: 20px;
            text-transform: uppercase;
            &:first-child {
              margin-top: 10px;
            }
            &.banfont {
              font-family: "Bahnschrift",arial;
              font-weight: 300;
            }
          }
        }
      }
    }
  }
  .pod-btn {
    font-family: $font-gals
  }
  .inner-contact-section {
    .title {
      font-family: $font-gals
    }
  }
}

.gtu-img {
  max-width: 396px;
}

.contact-in {
  text-align: center;
  margin-bottom: 17px;
  color: #fff;
  font-size: 18px;
  padding-left: 40px;
  padding-right: 40px;
}

.mw-640px {
  max-width: 640px;
}

.w33 {
  width: 33% !important;
  @media (max-width: 1099px) {
    width: 100% !important;
  }
}

.inner4-page {
  .inner1-info-section .info-center {
    max-width: 670px;
  }
  .inner1-info-section .info-tree-section .info2 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .inner1-info-section .info-tree-section .info3 {
    padding-left: 60px;
      @media (max-width: 1099px) {
       padding-left: 0px;
    }
  }
  .inner1-info-section .info-tree-section .info1 {
    padding-right: 60px;
    @media (max-width: 1099px) {
       padding-right: 0px;
    }
  }
}

.contact-column {
    display: flex;
    align-items: flex-start;
    justify-content: space-evenly;
    flex-direction: row-reverse;
    max-width: 1500px;
    margin: 0 auto;
    margin-bottom: 60px;
    @media (max-width:930px) {
      flex-direction: column;
      align-items: center;
    }
    .left-prt {
      width: 450px;
    }
    .right-prt {
      width: 450px;
    }
}

.bns-for-btn {
  font-family: "Bahnschrift",arial !important;
  margin-top: -5px !important;
}

.sc-wrap {
  @media (min-width: 1600px) {
    max-width: 1600px;
    margin: 0 auto;
    .sectionsz6 {
      background-size: 100%;
    }
    .sectionsz2 {
      background-size: 100%;
    }
    .sectionsz {
      background-size: 100%;
    }
  }
}

.bbg {
  background-color: #000;
}

.fp-tableCell {
    display: flex;
    justify-content: space-around;
    align-items: flex-end;
    padding-bottom: 50px;
    // @media (min-width: 1600px) {
    //     transform: scale(1.2);
    //   }
      @media (max-width: 768px) {
        flex-direction: column;
        align-items: center;
      }
}

.sectionsz5 {
  .fp-tableCell {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    align-items: center;
  }
}

.main-section {
  .fp-tableCell {
        display: table-cell; 
        // @media (min-width: 1600px) {
        //       transform: scale(1.2);
        // }
        // @media (min-width: 2500px) {
        //       transform: scale(1.4); 
        // }
  } 
}

.fix-flex {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    padding-left: 15px;
    @media (min-width:2000px) {
        flex-direction: column-reverse;
        img {
          margin-bottom: 40px;
        }
    }
}

// .desc1024w {
//   @media (max-height: 700px) {
//     margin-top: -130px;
//   }
// }