/* Global variables ----------------------------------
 ----------------------------------------------------- */
$font-regular: 'Avanti', arial;
$font-bahnschrift: 'Bahnschrift', arial;
$font-gals: 'Gals', arial;

$fw-light: 300;
$fw-bold: 700;
$fw-black: 900;
/* Color variables ----------------------------------
 ----------------------------------------------------- */
$body-bg:              #f2f3f5;
$text-primary-cl:      #393939;
$border-primary-cl:    #ebeef5;

$white-cl:             #fff;
$black-cl:             #000;
$grey-cl:              #a1a1a1;
$grey-light-cl:        #fafafa;

$dark-blue-cl:         #363b4e;

$accent-dark-cl:       #f47920;
$accent-light-cl:      #fcb614;

$primary-cl:           #00b4df;
$primary-dark-cl:      #00468b;

$success-cl:           #13ce66;
$error-cl:             #ff4949;

/* General variables ----------------------------------
 ----------------------------------------------------- */
$headerHeight :         66px;

/* Font size variables ----------------------------------
 ----------------------------------------------------- */
